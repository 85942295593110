export const HOGAST_UNIONS = [
    {
        id: "0998",
        gln: "4260112990002",
        email: "edi.germany@hogast.at",
        name: "HOGAST Einkaufsgesellschaft für das Hotel- und Gastgewerbe mbH",
        address: "Türkenstrasse 7",
        postalCode: "80333",
        city: "München",
        country: "DE",
        vatId: "DE199332230",
    },
    {
        id: "0989",
        email: "edifact@hogast.at",
        gln: "9006140000000",
        name: "HOGAST Einkaufsgenossenschaft f. d. Hotel- u. Gastgewerbe reg.Gen.mbH",
        address: "Sonystraße 4",
        postalCode: "A-5081",
        city: "Anif",
        country: "AT",
        vatId: "ATU34003805",
    },
    {
        id: "90989",
        email: "edi.hgp@hogast.at",
        gln: "9099999103404",
        name: "Hotel Gastro Pool GmbH",
        address: "Sonystrasse 4",
        postalCode: "A-5081",
        city: "Anif",
        country: "AT",
        vatId: "ATU61283603",
    },
    {
        id: "50002",
        email: "edi.handover@hogast.at",
        gln: "9008791000002",
        name: "HandOver Beschaffungsdienstleistungs GmbH",
        address: "Sonystraße 4",
        postalCode: "A-5081",
        city: "Anif",
        country: "AT",
        vatId: "ATU46903203",
    },
];

export type HogastUnionId = (typeof HOGAST_UNIONS)[number]["id"];
