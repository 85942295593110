import { LitElement, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import "./popover";
import "./scroller";
import { live } from "lit/directives/live.js";
import { Employee } from "packages/core/src/model";
import type { Popover } from "./popover";

@customElement("ptc-select-employee")
export class SelectEmployee extends LitElement {
    @property({ type: Number })
    employeeId?: number;

    @property()
    name: string;

    @state()
    private _filterString = "";

    @query("#selectionPopover")
    private _selectionPopover: Popover;

    @query("input[type=search]")
    private _filterInput: HTMLInputElement;

    @property({ attribute: false, type: Array })
    public employees: Employee[];

    protected createRenderRoot() {
        return this;
    }

    render() {
        const selected = this.employees.find((e) => e.id === this.employeeId);

        return html`
            <button class="fill-horizontally text-left-aligning ghost" type="button">
                <div class="bold center-aligning spacing horizontal layout">
                    ${this.employeeId && selected
                        ? html`
                              <div class="stretch ellipsis">${selected.name}</div>
                              <div class="subtle">#${selected.id}</div>
                          `
                        : html`<div class="faded stretch">[Kein Mitarbeiter augewählt]</div>`}
                    <i class="caret-down"></i>
                </div>
            </button>

            <ptc-popover
                id="selectionPopover"
                style="min-width: 18em;"
                class="small"
                alignment="bottom"
                @popover-show=${() => {
                    this._filterString = this._filterInput.value = "";
                    this._filterInput.focus();
                    this.requestUpdate();
                }}
            >
                <div class="vertical layout">
                    <div class="left icon input">
                        <i class="search"></i>
                        <input
                            type="search"
                            placeholder="Filtern..."
                            @input=${() => (this._filterString = this._filterInput.value)}
                        />
                    </div>
                </div>
                <ptc-scroller style="max-height: 50vh">
                    <div class="top-padded half-spacing vertical layout">
                        ${!this.employees.length
                            ? html` <div class="padded subtle">Keine Mitarbeiter verfügbar</div> `
                            : this.employees
                                  .filter(({ name, id }) =>
                                      `${name} ${id}`.toLowerCase().includes(this._filterString.toLowerCase())
                                  )
                                  .map(
                                      (e) => html`
                                          <button
                                              type="button"
                                              class="text-left-aligning slim transparent"
                                              @click=${() => {
                                                  this.employeeId = e.id;
                                                  this.dispatchEvent(
                                                      new CustomEvent("change", {
                                                          detail: { employeeId: this.employeeId },
                                                      })
                                                  );
                                                  this._selectionPopover.hide();
                                              }}
                                          >
                                              <div class="bold center-aligning spacing horizontal layout">
                                                  <div class="stretch">${e.name}</div>
                                                  <div class="subtle smaller">#${e.id}</div>
                                              </div>
                                          </button>
                                      `
                                  )}
                    </div></ptc-scroller
                >
            </ptc-popover>

            <input type="hidden" .name=${this.name} .value=${live(this.employeeId?.toString() || "")} />
        `;
    }
}
