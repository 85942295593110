import { html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { Invoice, InvoiceStatus } from "@pentacode/core/src/invoice";
import { shared } from "../styles";
import { Dialog } from "./dialog";
import "./invoice";
import "./scroller";
import { formatDate } from "@pentacode/core/src/util";

@customElement("ptc-invoice-dialog")
export class InvoiceDialog extends Dialog<Invoice, void> {
    @state()
    private _invoice: Invoice;

    async show(invoice: Invoice) {
        this._invoice = invoice;
        return super.show();
    }

    private _statusIcon(invoice: Invoice) {
        switch (invoice.status) {
            case InvoiceStatus.Open:
                return "orange colored-text clock";
            case InvoiceStatus.Paid:
                return "green colored-text check";
            case InvoiceStatus.Refunded:
                return "undo";
            case InvoiceStatus.Uncollectible:
            case InvoiceStatus.Disputed:
                return "red colored-text times";
            default:
                return "question";
        }
    }

    static styles = [
        shared,
        ...Dialog.styles,
        css`
            .scrim {
                background: none;
            }

            .inner {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 0;
                max-width: none;
                background: rgba(0, 0, 0, 0.8);
                display: flex;
                flex-direction: column;
            }

            .header {
                background: var(--color-bg);
            }

            ptc-invoice {
                margin: 3em auto;
                border-radius: calc(2 * var(--border-radius));
                width: 21cm;
                height: 29.7cm;
            }

            ptc-scroller {
                flex: 1;
            }

            @media print {
                ptc-invoice {
                    margin: 0 auto;
                    border-radius: 0;
                    width: 100%;
                    box-sizing: border-box;
                    max-width: 21cm;
                    height: 27.5cm;
                    padding: 2em;
                }

                .inner {
                    display: block;
                    background: #fff;
                }
            }
        `,
    ];

    updated(changedProperties: Map<string, unknown>) {
        if (changedProperties.has("_invoice")) {
            this.reloadObject();
        }
    }

    reloadObject() {
        const objectElement = this.renderRoot.querySelector("object");
        if (objectElement && typeof this._invoice.pdf === "string") {
            // Temporarily set data to null to force a reload of object element with the new PDF
            objectElement.data = "";
            setTimeout(() => {
                objectElement.data = this._invoice.pdf ?? "";
            }, 0);
        }
    }

    renderContent() {
        const invoice = this._invoice;
        return html`
            <div class="header horizontal center-aligning layout padded-medium pad-children ${invoice.status}">
                <i class="${this._statusIcon(invoice)}"></i>
                <div class="bold">
                    ${invoice.billingDate ? formatDate(invoice.billingDate) : formatDate(invoice.period.start)}
                </div>
                <div class="stretch ellipsis">${invoice.items.map((item) => item.description).join(", ")}</div>
                <button class="slim transparent" @click=${() => this.done()}><i class="times"></i></button>
            </div>
            ${invoice.pdf
                ? html` <object type="application/pdf" data="${invoice.pdf}" style="flex: 1"></object> `
                : html`
                      <ptc-scroller>
                          <ptc-invoice .invoice=${invoice}></ptc-invoice>
                      </ptc-scroller>
                  `}
        `;
    }
}
