import { styleMap } from "lit/directives/style-map.js";
import { shared } from "../styles";
import { css, html, LitElement } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { version, VersionInfo } from "@pentacode/core/src/version";
import { router } from "../init";

@customElement("ptc-update-notification")
export class UpdateNotification extends LitElement {
    @property({ attribute: false })
    private _versionInfo: VersionInfo;

    @state()
    private _countdown: number = 0;

    @state()
    private _countdownRemaining: number = 0;

    private _interval: number;
    private _resolve: ((...args: any[]) => void) | null = null;

    render() {
        const countdown = this._countdown;
        const remaining = this._countdownRemaining;

        return html`
            <div class="double-padded inner vertical layout" tabindex="-1">
                <div class="large semibold"><i class="arrow-alt-circle-up"></i> Neue Version Verfügbar</div>

                <div class="padded bottom-margined">
                    Pentacode <strong>Version ${this._versionInfo.version}</strong> ist verfügbar! Das Update wird beim
                    Neu-Laden der Seite automatisch installiert. Eine Liste aller Änderungen finden Sie
                    <a href="https://pentacode.app/hilfe/aenderungsprotokoll/" target="_blank">hier</a>.
                </div>

                <div class="spacing horizontal stretching layout">
                    <button class="primary" @click=${this._countdownFinished}>
                        <div class="spacing horizontal center-aligning layout text-left-aligning">
                            <div class="stretch">Installieren & Neu Starten</div>
                            <div class="relative" style="margin: -0.5em 0">
                                <svg class="countdown-wheel" viewBox="0 0 10 10">
                                    <circle
                                        cx="5"
                                        cy="5"
                                        r="4"
                                        style=${styleMap({
                                            strokeDashoffset: ((1 - remaining / countdown) * 25).toString(),
                                        })}
                                    />
                                </svg>

                                <div class="countdown centering layout">
                                    <div class="tiny">${remaining}s</div>
                                </div>
                            </div>
                        </div>
                    </button>

                    <button class="transparent" @click=${this.cancel}>Später</button>
                </div>
            </div>
        `;
    }

    async show(versionInfo: VersionInfo, countdown = 30) {
        clearInterval(this._interval);

        const tStart = Date.now();

        this._versionInfo = versionInfo;
        this._countdown = this._countdownRemaining = countdown;
        this._interval = window.setInterval(() => {
            const dt = tStart + countdown * 1000 - Date.now();
            if (dt <= 0) {
                this._countdownFinished();
            } else {
                this._countdownRemaining = Math.floor(dt / 1000);
            }
        }, 1000);

        setTimeout(() => this.classList.add("showing"), 10);

        return new Promise((resolve) => {
            this._resolve = resolve;
        });
    }

    cancel() {
        clearInterval(this._interval);
        this.classList.remove("showing");
        this._resolve && this._resolve();
        this._resolve = null;
    }

    private _countdownFinished() {
        router.params = { ...router.params, showChangesSince: version };
        window.location.reload();
    }

    static styles = [
        shared,
        css`
            :host {
                display: flex;
                justify-content: center;
                transition: transform 0.5s cubic-bezier(1, -0.3, 0, 1.3);
                position: fixed;
                right: 2em;
                bottom: 2em;
                z-index: 100;
                width: 30em;
                pointer-events: none;
            }

            :host(:not(.showing)) {
                transform: translateY(150%);
            }

            .inner {
                background: var(--color-bg);
                border-radius: 0.5em;
                pointer-events: auto;
                max-width: 100%;
                box-shadow:
                    rgba(0, 0, 0, 0.1) 0 0.3em 1em -0.2em,
                    var(--shade-1) 0 0 0 1px;
            }

            .countdown-wheel {
                position: absolute;
                width: 2.5em;
                height: 2.5em;
                margin: auto;
                border-radius: 100%;
            }

            .countdown-wheel circle {
                transform-origin: center center;
                transform: rotate(-90deg);
                fill: none;
                stroke: currentColor;
                stroke-width: 0.5;
                stroke-dasharray: 25;
                stroke-linecap: round;
                transition: stroke-dashoffset 1s linear;
            }

            .countdown {
                width: 2.5em;
                height: 2.5em;
            }

            @media (max-width: 500px) {
                :host {
                    right: 0.5em;
                    bottom: 0.5em;
                    left: 0.5em;
                    width: auto;
                }
            }
        `,
    ];
}
