import { LitElement, html } from "lit";
import { shared } from "../../styles";
import { customElement, property, query, state } from "lit/decorators.js";
import { DateString } from "packages/openapi/src/units";
import { Checkbox } from "../checkbox";
import { DateRange } from "@pentacode/core/src/time";
import { getRange } from "@pentacode/core/src/util";
import { app } from "../../init";
import { Employee, TimeEntry, TimeEntryType } from "@pentacode/core/src/model";

import "../date-range-picker";
import "../select-employee";

@customElement("ptc-restore-time-entries-form")
export class RestoreTimeEntriesForm extends LitElement {
    @property({ type: Number })
    public companyId: number;

    @property({ attribute: false, type: Array })
    public employees: Employee[];

    @query("form")
    private _form: HTMLFormElement;

    @state()
    private _employeeId: number | null;

    @state()
    private _dateFrom: DateString | null;

    @state()
    private _dateTo: DateString | null;

    @state()
    private entries: TimeEntry[] = [];

    private get dateRange(): DateRange | null {
        return this._dateFrom && this._dateTo ? { from: this._dateFrom, to: this._dateTo } : null;
    }

    get data() {
        const formData = this._form && new FormData(this._form);

        if (!formData || !this._employeeId || !this._dateFrom || !this._dateTo || !this.entries.length) {
            return null;
        }

        return {
            employeeId: this._employeeId,
            from: this._dateFrom,
            to: this._dateTo,
        };
    }

    static styles = [shared, Checkbox.styles];

    protected async updated(_changedProperties: Map<string, any>) {
        if (
            _changedProperties.has("_dateFrom") ||
            _changedProperties.has("_dateTo") ||
            _changedProperties.has("_employeeId")
        ) {
            if (this._employeeId && this._dateFrom && this._dateTo) {
                const entries = await app.getTimeEntries({
                    from: this._dateFrom,
                    to: this._dateTo,
                    includeDeleted: true,
                    employee: this._employeeId,
                    companyId: this.companyId,
                });

                this.entries = entries
                    .filter((e) => !!e.deleted)
                    .filter((e) => e.type !== TimeEntryType.Work || (e.startFinal && e.endFinal));
            } else {
                this.entries = [];
            }

            this.dispatchEvent(new CustomEvent("changed"));
        }
    }

    render() {
        return html`
            <form @submit=${this._submit}>
                <div class="padded">
                    <div class="field">
                        <label for="employeeId">Mitarbeiter</label>
                        <ptc-select-employee
                            @change=${(e: CustomEvent) => {
                                this._employeeId = e.detail?.employeeId;
                            }}
                            .employeeId=${this._employeeId}
                            .employees=${this.employees}
                        >
                        </ptc-select-employee>
                    </div>
                    <div class="field">
                        <label>Zeitraum</label>
                        <ptc-date-range-picker
                            @range-selected=${(e: CustomEvent<DateRange>) => {
                                this._dateFrom = e.detail.from;
                                this._dateTo = e.detail.to;
                            }}
                            .range=${this.dateRange}
                            .rangeType=${"month"}
                        ></ptc-date-range-picker>
                    </div>
                    ${this.entries.length > 0
                        ? html`<div class="small subtle red colored-text">
                              <i class="exclamation-triangle"></i>
                              Es werden ${this.entries.length} Einträge wiederhergestellt!
                          </div>`
                        : html`<div class="small subtle">
                              <i class="info"></i>
                              Keine Einträge zum Wiederherstellen gefunden.
                          </div>`}
                </div>
            </form>
        `;
    }

    firstUpdated() {
        const currentMonth = getRange(new Date(), "month");
        this._employeeId = null;
        this._dateFrom = currentMonth.from;
        this._dateTo = currentMonth.to;
        this.entries = [];
    }

    reset() {
        this.firstUpdated();
    }

    private _submit(e: FocusEvent) {
        e.preventDefault();
        this.dispatchEvent(new CustomEvent("submit", { detail: { data: this.data } }));
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "ptc-unlock-time-entries-form": RestoreTimeEntriesForm;
    }
}
