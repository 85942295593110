import { LitElement, html } from "lit";
import { shared } from "../../styles";
import { customElement, property, query, state } from "lit/decorators.js";
import { DateString } from "packages/openapi/src/units";
import { Checkbox } from "../checkbox";

import "../date-range-picker";
import { app } from "../../init";

@customElement("ptc-unlock-revenue-entries-form")
export class UnlockRevenueEntriesForm extends LitElement {
    @property({ type: Number })
    public companyId: number;

    @query("form")
    private _form: HTMLFormElement;

    @state()
    private dateFrom: DateString | null;

    @state()
    private entriesCount: number | null;

    get data() {
        const formData = this._form && new FormData(this._form);

        if (!formData || !this.dateFrom || !this.entriesCount) {
            return null;
        }

        return {
            companyId: this.companyId,
            from: this.dateFrom,
        };
    }

    static styles = [shared, Checkbox.styles];

    protected async updated(_changedProperties: Map<string, any>) {
        if (_changedProperties.has("dateFrom")) {
            if (this.dateFrom && this.companyId) {
                this.entriesCount = await app.api.getRevenueEntriesCount({
                    from: this.dateFrom!,
                    companyId: this.companyId,
                });
            } else {
                this.entriesCount = null;
            }

            this.dispatchEvent(new CustomEvent("changed"));
        }
    }

    render() {
        return html`
            <form @submit=${this._submit}>
                <div class="padded">
                    <div class="small subtle">Alle Umsätze ab diesem Datum werden für alle Standorte entsperrt.</div>
                    <div>
                        <ptc-date-picker
                            @change=${(e: CustomEvent<{ value: DateString }>) => {
                                this.dateFrom = e.detail.value;
                            }}
                        ></ptc-date-picker>
                    </div>
                    ${this.entriesCount
                        ? html`<div class="vertically-margined small subtle red colored-text">
                              <i class="exclamation-triangle"></i>
                              Es werden ${this.entriesCount} Umsätze entsperrt!
                          </div>`
                        : html`<div class="vertically-margined small subtle">
                              <i class="info"></i>
                              Keine Umsätze zum Entsperren gefunden.
                          </div>`}
                </div>
            </form>
        `;
    }

    firstUpdated() {
        this.dateFrom = null;
        this.entriesCount = null;
    }

    reset() {
        this.firstUpdated();
    }

    private _submit(e: FocusEvent) {
        e.preventDefault();
        this.dispatchEvent(new CustomEvent("submit", { detail: { data: this.data } }));
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "ptc-unlock-revenue-entries-form": UnlockRevenueEntriesForm;
    }
}
