import { css, html } from "lit";
import { customElement, query } from "lit/decorators.js";
import { Dialog } from "../dialog";
import { app } from "../../init";
import { alert, confirm } from "../alert-dialog";
import { UnlockRevenueEntriesForm } from "./unlock-revenue-entries-form";

@customElement("ptc-unlock-revenue-entries-dialog")
export class UnlockRevenueEntriesDialog extends Dialog<{ companyId: number }, void> {
    readonly preventDismiss = true;

    @query("ptc-unlock-revenue-entries-form")
    private _form: UnlockRevenueEntriesForm;

    static styles = [
        ...Dialog.styles,
        css`
            .inner {
                padding: 1em;
            }

            .actions > :not(:last-child) {
                margin-right: 0.7em;
            }
        `,
    ];

    renderContent() {
        return html`
            <h3>Umsätze entsperren</h3>

            <ptc-unlock-revenue-entries-form
                .companyId=${this.input.companyId}
                @submit=${this._submit}
                @changed=${() => this.requestUpdate()}
                @cancel=${() => this.done()}
            ></ptc-unlock-revenue-entries-form>

            <div class="horizontal layout actions">
                <button class="primary stretch" @click=${this._submit} ?disabled=${!this._form?.data}>
                    Entsperren
                </button>
                <button class="stretch" @click=${this.done}>Abbrechen</button>
            </div>
        `;
    }

    private async _submit() {
        if (!this._data) {
            return;
        }

        if (
            !(await confirm("Willst du diese Umsätze entsperren?", "Entsperren", "Abbrechen", {
                title: "Umsätze entsperren",
                type: "question",
            }))
        ) {
            return;
        }

        this.loading = true;
        try {
            await app.api.unlockRevenueEntries({ ...this._data });
            this._form.reset();
            await alert("Die Umsätze wurden erfolgreich entsperrt.", { type: "success" });
        } catch (e) {
            this.hide();
            await alert(e.message, { type: "warning" });
            void this.show(this.input);
        }

        this.loading = false;
        this.done();
    }

    private get _data() {
        if (!this._form?.data) {
            return null;
        }

        const { companyId, from } = this._form.data;

        if (!companyId || !from) {
            return null;
        }

        return { companyId, from };
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "ptc-unlock-revenue-entries-dialog": UnlockRevenueEntriesDialog;
    }
}
