import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { Invoice, InvoiceStatus } from "@pentacode/core/src/invoice";
import { formatDate, formatNumber } from "@pentacode/core/src/util";
import { shared } from "../styles";
import Logo from "../img/logo.svg";

@customElement("ptc-invoice")
export class InvoiceElement extends LitElement {
    @property({ attribute: false })
    invoice: Invoice;

    static styles = [
        shared,
        css`
            :host {
                background: #fff;
                display: grid;
                grid-gap: 3em;
                grid-template:
                    "sender sender logo" auto
                    "recipient info info" auto
                    "header header header" auto
                    "details details details" 1fr
                    "company board bank" auto / 1fr 1fr 1fr;
                padding: 4em;
            }

            .header {
                grid-area: header;
                font-size: var(--font-size-huge);
            }

            .sender {
                grid-area: sender;
                text-align: left;
                font-size: var(--font-size-small);
            }

            .logo {
                grid-area: logo;
                text-align: right;
            }

            .recipient {
                grid-area: recipient;
                width: 150%;
            }

            .info {
                grid-area: info;
            }

            .info > table {
                float: right;
                text-align: left;
            }

            .info th {
                padding-right: 1em;
                font-weight: 600;
            }

            .info td {
                text-align: right;
            }

            .details {
                grid-area: details;
            }

            .items {
                width: 100%;
                text-align: left;
            }

            .items thead tr > *,
            .items tbody tr > :not(:empty) {
                border-bottom: solid 1px;
            }

            .items tbody tr:not(:last-child) > * {
                border-style: dashed;
            }

            .items th {
                padding: 0.5em;
            }

            .items td {
                padding: 0.5em;
            }

            .items tbody tr.total > * {
                border-style: solid;
            }

            .items .amount {
                text-align: right;
            }

            .sum .amount {
                text-align: right;
            }

            .sum th {
                padding: 0.5em;
                padding-right: 2em;
            }

            .sum td {
                padding: 0.5em;
                text-align: right;
            }

            .sum tr:not(:first-child) {
                border-top: dashed 1px;
            }

            .sum tr.total {
                border-top: solid 2px;
                font-weight: bold;
            }

            .bank {
                grid-area: bank;
                font-size: var(--font-size-tiny);
            }

            .company {
                grid-area: company;
                font-size: var(--font-size-tiny);
            }

            .board {
                grid-area: board;
                font-size: var(--font-size-tiny);
            }
        `,
    ];

    render() {
        if (!this.invoice) {
            return;
        }

        const {
            number,
            billingDate,
            customerInfo: customer,
            items,
            creditItems,
            taxes,
            creditTaxes,
            subtotal,
            total,
            adjustedTotal,
            refund,
            status,
            creditSubTotal,
            invoiceDiscounts,
            creditDiscounts,
        } = this.invoice;

        return html`
            <div class="sender">
                <strong>Pentacode AG</strong><br />
                Lindwurmstrasse 147<br />
                80337 München
            </div>

            <div class="logo">
                <img class="fit" src=${Logo} />
            </div>

            <div class="recipient">
                <strong>Rechnungsempfänger</strong><br />
                ${customer.name}<br />
                ${customer.addressLine1}<br />
                ${customer.postalCode} ${customer.city}, ${customer.country}<br />
                ${customer.email}<br />
                ${customer.taxId ? `USt-ID: ${customer.taxId}` : ""}
            </div>

            <div class="info">
                <table>
                    <tr>
                        <th>Rechnungsnummer</th>
                        <td>${number}</td>
                    </tr>
                    ${billingDate
                        ? html`
                              <tr>
                                  <th>Rechnungsdatum</th>
                                  <td>${formatDate(billingDate)}</td>
                              </tr>
                          `
                        : ""}
                    ${refund
                        ? html`
                              <tr>
                                  <th>Zurückerstattet am</th>
                                  <td>${formatDate(refund.date)}</td>
                              </tr>
                          `
                        : ""}
                    <tr>
                        <td colspan="2" class="smaller subtle" style="text-align: left">
                            Wo nicht explizit angeben:<br />
                            <strong>Leistungsdatum entspricht dem Rechnungsdatum</strong>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="horizontal layout header">
                <div class="stretch">${status === InvoiceStatus.Refunded ? "Stornorechnung" : "Rechnung"}</div>
                ${[InvoiceStatus.Disputed, InvoiceStatus.Uncollectible].includes(status)
                    ? html`<div class="red colored-text">Angefochten / Storniert</div>`
                    : status === InvoiceStatus.Paid
                      ? html` <div class="green colored-text">Bezahlt</div> `
                      : ""}
            </div>

            <div class="details vertical layout">
                <table class="items">
                    <thead>
                        <tr>
                            <th>Beschreibung</th>
                            <th>Menge</th>
                            <th>Preis pro Einheit</th>
                            <th>Steuer</th>
                            <th class="amount">Betrag</th>
                        </tr>
                    </thead>

                    <tbody>
                        ${items.map(
                            (item) => html`
                                <tr>
                                    <td>
                                        ${item.description}
                                        ${item.period
                                            ? html`
                                                  <div class="tiny">
                                                      <strong>Leistungszeitraum:</strong>
                                                      ${item.period}
                                                  </div>
                                              `
                                            : ""}
                                    </td>
                                    <td>${item.quantity}</td>
                                    <td>${formatNumber(item.price)} €</td>
                                    <td>${item.taxRate} %</td>
                                    <td class="amount">${formatNumber(item.baseAmount)} €</td>
                                </tr>
                            `
                        )}

                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <th>Zwischensumme</th>
                            <td class="amount">${formatNumber(subtotal)} €</td>
                        </tr>

                        ${invoiceDiscounts.map(
                            (discount) => html`
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th>${discount.description}</th>
                                    <td class="amount">${formatNumber(discount.amount)} €</td>
                                </tr>
                            `
                        )}
                        ${taxes.map(
                            (tax) => html`
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th>MwSt. ${tax.rate} %</th>
                                    <td class="amount">${formatNumber(tax.amount)} €</td>
                                </tr>
                            `
                        )}
                        ${creditItems.length
                            ? html`
                                  <tr class="total bold">
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <th>Gesamtbetrag</th>
                                      <td class="amount">${formatNumber(total)} €</td>
                                  </tr>

                                  ${creditItems.map(
                                      (item) => html`
                                          <tr>
                                              <td>
                                                  Gutschrift: ${item.description}
                                                  ${item.period
                                                      ? html`
                                                            <div class="tiny">
                                                                <strong>Leistungszeitraum:</strong>
                                                                ${item.period}
                                                            </div>
                                                        `
                                                      : ""}
                                              </td>
                                              <td>${item.quantity}</td>
                                              <td>${formatNumber(item.price)} €</td>
                                              <td>${item.taxRate} %</td>
                                              <td class="amount">${formatNumber(item.baseAmount)} €</td>
                                          </tr>
                                      `
                                  )}

                                  <tr>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <th>Zwischensumme Gutschriften</th>
                                      <td class="amount">${formatNumber(creditSubTotal)} €</td>
                                  </tr>

                                  ${creditDiscounts.map(
                                      (discount) => html`
                                          <tr>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <th>${discount.description}</th>
                                              <td class="amount">${formatNumber(discount.amount)} €</td>
                                          </tr>
                                      `
                                  )}
                                  ${creditTaxes.map(
                                      (tax) => html`
                                          <tr>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <th>MwSt. ${tax.rate} %</th>
                                              <td class="amount">${formatNumber(tax.amount)} €</td>
                                          </tr>
                                      `
                                  )}

                                  <tr class="total bold">
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <th>Korrigierter Gesamtbetrag</th>
                                      <td class="amount">${formatNumber(adjustedTotal)} €</td>
                                  </tr>
                              `
                            : html`
                                  <tr class="total bold">
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <th>Gesamtbetrag</th>
                                      <td class="amount">${formatNumber(total)} €</td>
                                  </tr>
                                  ${refund
                                      ? html`
                                            <tr class="refund">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <th>Zurückerstattet</th>
                                                <td class="amount">-${formatNumber(refund.amount)} €</td>
                                            </tr>

                                            <tr class="total bold">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <th>Korrigierter Gesamtbetrag</th>
                                                <td class="amount">${formatNumber(total - refund.amount)} €</td>
                                            </tr>
                                        `
                                      : ""}
                              `}
                    </tbody>
                </table>

                ${status === "paid"
                    ? html`
                          <div class="stretch"></div>
                          <div class="large double-margined double-padded text-centering">
                              Diese Rechnung wurde bereits beglichen.
                          </div>
                          <div class="stretch"></div>
                      `
                    : ""}
            </div>

            <div class="company">
                <strong>Pentacode AG</strong><br />
                Lindwurmstrasse 147<br />
                80337 München<br />
                HRB 196631<br />
                Steuernummer: 143/101/62826<br />
                Ust-Id: DE282398652
            </div>

            <div class="board">
                <strong>Vorstand</strong><br />
                Lorenz Strasser<br />
                <strong>Aufsichtsrat</strong><br />
                Klaus Wigand (Vorsitzender)<br />
                Jürgen Strasser<br />
                Marco Zaretti
            </div>

            <div class="bank">
                <strong>Bankverbindung</strong><br />
                PostBank<br />
                Kto: 46 174 808<br />
                BLZ: 700 100 80IBAN<br />
                IBAN: DE81 7001 0080 0046 1748 08<br />
                BIC: PBNKDEFF
            </div>
        `;
    }
}
