// @ts-ignore
import "../../vendor/beacon";
import { Role } from "@pentacode/core/src/model";
import { version } from "@pentacode/core/src/version";
import { app, router } from "../init";

declare let Beacon: any;

export function contactSupport(subject = "") {
    Beacon("session-data", {
        "App Version": version,
        "Company ID": app.company?.id,
        "Session ID": (app.state.session && app.state.session.id) || "",
    });
    Beacon("prefill", {
        subject,
        fields: [{ id: 33862, value: app.company?.id }],
    });
    Beacon("navigate", "/ask/message/");
    Beacon("open");
    Beacon("once", "close", () => {
        Beacon("prefill", { subject: "" });
        Beacon("navigate", "/");
    });
}

export function initHelpWidget() {
    Beacon("config", {
        display: { position: "left" },
        messaging: {
            contactForm: {
                customFieldsEnabled: true,
            },
        },
        showPrefilledCustomFields: false,
    });

    app.subscribe(() => {
        const role = app.profile && app.profile.role;
        const roleTitle =
            role === null
                ? ""
                : role === Role.Admin
                  ? "Admin"
                  : role === Role.Owner
                    ? "Besitzer"
                    : role === Role.Manager
                      ? "Manager"
                      : "Mitarbeiter";

        const params = {
            email: (app.account && app.account.email) || "",
            name: (app.account && app.account.name) || "",
            jobTitle: roleTitle,
            accountId: (app.account && app.account.id) || "",
            sessionId: (app.state.session && app.state.session.id) || "",
            company: (app.company && app.company.name) || "",
            companyId: (app.company && app.company.id) || "",
            companyPhone: (app.company && app.company.phone) || "",
            employeePhone: (app.profile && app.profile.phone) || "",
            appVersion: version,
            role: roleTitle,
        } as any;

        if (app.profile && app.profile.avatar) {
            params.avatar = app.profile.avatar;
        }

        Beacon("identify", params);
    });

    router.addEventListener("route-changed", () => {
        setTimeout(() => {
            Beacon("event", {
                type: "page-viewed",
                url: window.location.href,
                title: document.title,
            });
            Beacon("suggest");
        }, 100);
    });
}

initHelpWidget();
