import { Dialog } from "../dialog";
import { html, css } from "lit";
import { Checkbox } from "../checkbox";
import { customElement, query } from "lit/decorators.js";
import { Company, CompanyStatus, companyStatusLabel } from "@pentacode/core/src/model";
import { DateInput } from "../date-input";
import { app } from "../../init";
import { CreateCompanyParams, CreateVenueParams } from "@pentacode/core/src/api";
import { alert } from "../alert-dialog";
import "../scroller";
import { Localized, StateCode } from "@pentacode/core/src/localized";

@customElement("ptc-new-company-dialog")
export class NewCompanyDialog extends Dialog<Company, Company> {
    preventDismiss = true;

    @query("form")
    private _form: HTMLFormElement;

    @query("select[name='country']")
    private _countrySelect: HTMLSelectElement;

    private async _submit(e: Event) {
        e.preventDefault();

        const data = new FormData(this._form);

        this.loading = true;
        try {
            const address = data.get("address") as string;
            const city = data.get("city") as string;
            const postalCode = data.get("postalCode") as string;
            const company = await app.api.createCompany(
                new CreateCompanyParams({
                    name: data.get("name") as string,
                    country: data.get("country") as "DE" | "AT",
                    address,
                    city,
                    postalCode,
                    phone: "",
                    email: data.get("email") as string,
                    status: data.get("status") as CompanyStatus,
                    owner: {
                        email: data.get("email") as string,
                        password: data.get("password") as string,
                        firstName: data.get("firstName") as string,
                        lastName: data.get("lastName") as string,
                    },
                    venue: new CreateVenueParams({
                        name: data.get("venueName") as string,
                        address,
                        city,
                        postalCode,
                        state: data.get("state") as StateCode,
                    }),
                })
            );

            this._form.reset();

            this.loading = false;

            this.done(company);
        } catch (e) {
            this.loading = false;

            await alert(e.message, { type: "warning" });
        }
    }

    async show() {
        const promise = super.show();
        await this.updateComplete;
        return promise;
    }

    static styles = [
        ...Dialog.styles,
        Checkbox.styles,
        DateInput.styles,
        css`
            .inner {
                --dialog-max-width: 500px;
                --dialog-width: 500px;
            }
        `,
    ];

    renderContent() {
        const country = (this._countrySelect?.value || "DE") as "DE" | "AT";
        return html`
            <form class="vertical layout fit-vertically" @submit=${this._submit}>
                <div class="margined text-centering big">Neues Unternehmen</div>

                <ptc-scroller class="stretch">
                    <div class="padded">
                        <div class="field">
                            <label>Unternehmensname</label>

                            <input type="text" name="name" placeholder="Unternehmensname" required />
                        </div>

                        <div class="field">
                            <label>Status</label>
                            <select name="status" @change=${() => this.requestUpdate()}>
                                <option .value=${CompanyStatus.Trialing}>
                                    ${companyStatusLabel(CompanyStatus.Trialing)}
                                </option>
                                <option .value=${CompanyStatus.Probation}>
                                    ${companyStatusLabel(CompanyStatus.Probation)}
                                </option>
                                <option .value=${CompanyStatus.Active}>
                                    ${companyStatusLabel(CompanyStatus.Active)}
                                </option>
                            </select>
                        </div>

                        <h2>Standort</h2>

                        <div class="field">
                            <label>Land</label>
                            <select name="country" @change=${() => this.requestUpdate()}>
                                <option value="DE">Deutschland</option>
                                <option value="AT">Österreich</option>
                            </select>
                        </div>

                        <div class="field">
                            <label>Standortname</label>
                            <input type="text" name="venueName" placeholder="Standortname" required />
                        </div>

                        <div class="field">
                            <label>Adresse</label>
                            <input type="text" name="address" placeholder="Strasse / Hausnummer" required />
                        </div>

                        <div class="horizontal spacing layout">
                            <div class="field stretch">
                                <input type="number" pattern="d*" name="postalCode" placeholder="PLZ" required />
                            </div>

                            <div class="field stretch">
                                <input type="text" name="city" placeholder="Stadt" required />
                            </div>
                        </div>

                        <div class="field">
                            <label>Bundesland</label>

                            <select name="state" required>
                                ${Localized[country].states.map(
                                    (state) => html` <option value=${state.code}>${state.name}</option> `
                                )}
                            </select>
                        </div>

                        <h2>Eigentümer</h2>

                        <div class="horizontal spacing evenly stretching layout">
                            <div class="field">
                                <label>Vorname</label>

                                <input type="text" name="firstName" placeholder="Eigentümer Vorname" />
                            </div>

                            <div class="field">
                                <label>Nachname</label>

                                <input type="text" name="lastName" placeholder="Eigentümer Nachname" />
                            </div>
                        </div>

                        <div class="field">
                            <label>Benutzername / Email</label>

                            <input type="email" name="email" placeholder="E-mail Adresse" required />
                        </div>

                        <div class="field">
                            <label>Passwort</label>

                            <input type="password" name="password" placeholder="Passwort" required minlength="8" />
                        </div>
                    </div>
                </ptc-scroller>

                <div class="padded horizontal spacing evenly stretching layout">
                    <button class="primary">Unternehmen Erstellen</button>
                    <button class="transparent" type="button" @click=${() => this.done()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
